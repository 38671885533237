import { ProductQueryData } from "@plinknz/tah-website-elements";
import * as React from "react";
import * as ROUTES from "../../config/router";
import { useFlag } from "../../utility/hooks/use-flag";
import { useInterval } from "../../utility/hooks/use-interval";

export interface ProductProps {
    data: ProductQueryData;
    host?: string; // For tests
}

export const Product = ({ data, host = process.env.HOST }: ProductProps) => {
    const { product } = data;
    const [imageIndex, setCurrentImage] = React.useState(0);
    const [hovering, setHovering] = React.useState(false);
    const enabled = useFlag("ecommerce");

    const isTouchEnabled = () =>
        "ontouchstart" in window || navigator.maxTouchPoints > 0;

    const showNextImage = React.useCallback(() => {
        const nextIndex =
            imageIndex === product.images.length - 1 ? 0 : imageIndex + 1;

        setCurrentImage(nextIndex);
    }, [imageIndex]);

    const handleHover = React.useCallback(() => {
        if (!isTouchEnabled()) {
            setHovering(true);
            showNextImage();
        }
    }, [imageIndex]);

    useInterval(() => showNextImage(), hovering ? 700 : null);

    if (!enabled || !product) return null;

    const productUrl = `https://${host}${ROUTES.product(product.id)}`;

    return (
        <div
            className="product || constrain-width"
            data-testid={`product-${product.id}`}>
            <div
                className="product-media"
                onTouchStart={showNextImage}
                onFocus={() => handleHover()}
                onMouseOver={() => handleHover()}
                onBlur={() => setHovering(false)}
                onMouseOut={() => setHovering(false)}
                role="presentation"
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}>
                <img
                    src={product.images[imageIndex].url}
                    alt={product.images[imageIndex].alternativeText}
                    className="product-image"
                />
                {product.images.length > 0 && (
                    <p className="product-media-instruction">
                        <small>
                            Tap to see the next picture. {imageIndex + 1} of{" "}
                            {product.images.length}
                        </small>
                    </p>
                )}
            </div>
            <div className="product-content">
                <h2 className="product-title">{product.title}</h2>
                <h3 className="product-price">${product.price.toFixed(2)}</h3>
                <p className="product-description">{product.description}</p>
                <button
                    className="button primary large snipcart-add-item"
                    type="button"
                    data-item-id={product.id}
                    data-item-price={product.price}
                    data-item-url={productUrl}
                    data-item-description={product.description}
                    data-item-image={product.images[0].url}
                    data-item-name={product.title}
                    data-item-custom1-name={product.variations_1_title}
                    data-item-custom1-options={product.variations_1}
                    data-item-custom2-name={product.variations_2_title}
                    data-item-custom2-options={product.variations_2}
                    data-item-custom3-name={product.variations_3_title}
                    data-item-custom3-options={product.variations_3}
                    data-item-max-quantity={product.max_quantity}>
                    Add to Cart
                </button>
            </div>
        </div>
    );
};
