import type { ContentTypeData } from "@plinknz/tah-website-elements";
import * as React from "react";
import Markdown from "react-markdown";
import { generate } from "shortid";
import { GalleryBlock } from "./gallery";

export interface ContentProps {
    data: ContentTypeData;
}

export interface LinkRendererProps {
    href: string;
    children: React.ReactNode;
}

const LinkRenderer = ({ children, href }: LinkRendererProps) => (
    <a href={href} target="_blank" rel="noreferrer noopener">
        {children}
    </a>
);

interface ImageRendererProps {
    alt: string;
    src: string;
}

const ImageRenderer = ({ alt, src }: ImageRendererProps) => (
    <GalleryBlock
        data={{
            __typename: "ComponentContentBlocksImageGallery",
            id: generate(), // TODO: Find consistent id
            images: [
                {
                    image: {
                        url: src,
                        alternativeText: alt,
                        width: null,
                        height: null,
                    },
                },
            ],
        }}
    />
);

export const Content = ({ data }: ContentProps) => (
    <div data-testid="content">
        <Markdown
            className="content || constrain-width"
            linkTarget="blank"
            components={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                a: ({ _node, ...props }: any) => <LinkRenderer {...props} />,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                img: ({ node, ...props }: any) => <ImageRenderer {...props} />,
            }}>
            {data.content}
        </Markdown>
    </div>
);
