import type { FooterNavigationItem } from "@plinknz/tah-website-elements";

export const SOCIAL_LINKS: FooterNavigationItem[] = [
    {
        pages: [
            {
                title: "Facebook",
                slug: "https://www.facebook.com/ngatirangi",
                icon: "fab fa-facebook",
            },
            {
                title: "Instagram",
                slug: "https://www.instagram.com/ngati_rangi/",
                icon: "fab fa-instagram",
            },
            {
                title: "YouTube",
                slug: "https://www.youtube.com/playlist?list=UUPi4F3V1uhUJLGpOZPXGdlw",
                icon: "fab fa-youtube",
            },
        ],
    },
];
