import * as React from "react";
import { Link } from "react-router-dom";
import type { CallToActionData } from "@plinknz/tah-website-elements";
import { useVisible } from "react-visible-image";
import { formatMultilineText } from "@plinknz/tah-website-elements";
import { getMedia } from "../../service/api-media";

export interface CallToActionProps {
    data: CallToActionData;
}

export const CallToActionBlock = ({ data }: CallToActionProps) => {
    const { callToActionTitle, description, action, background } = data;
    const [url, setImageUrl] = React.useState<string>(
        background?.formats?.small.url // Use the smallest initially
    );
    const ctaElement = React.useRef<HTMLDivElement>();
    const isVisible = useVisible(ctaElement);

    React.useEffect(() => {
        // 600 so we don't get really small cover photos, should fix this in the future
        if (ctaElement.current) {
            const minSize = 600;
            const targetSize = Math.max(
                ctaElement.current.clientWidth,
                minSize
            );
            const bestUrl = getMedia(background?.url, {
                formats: background?.formats,
                targetSize,
            });

            setImageUrl(bestUrl);
        }
    }, [ctaElement.current, url]);

    return (
        <section
            className="call-to-action constrain-width"
            data-testid="call-to-action">
            <div
                className="call-to-action-inner"
                ref={ctaElement}
                style={{
                    backgroundImage: isVisible
                        ? `url("${
                              url ||
                              "/images/ngati-rangi-maunga 2019_DSC0175.JPG"
                          }")`
                        : undefined,
                }}>
                <div className="call-to-action-content">
                    <h2 className="call-to-action-header">
                        {callToActionTitle}
                    </h2>
                    {description && (
                        <div
                            className="call-to-action-description"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: formatMultilineText(description),
                            }}
                        />
                    )}
                    <Link className="button secondary" to={action.link?.slug}>
                        {action.label}
                    </Link>
                </div>
            </div>
        </section>
    );
};
