import * as React from "react";
import { Link } from "react-router-dom";
import { generate } from "shortid";
import type { PageData, RelatedPage } from "@plinknz/tah-website-elements";

interface MenuProps {
    links?: RelatedPage[];
    parent?: RelatedPage;
    currentPage: PageData;
    show: boolean;
}

export const Menu = ({ links = [], show, parent, currentPage }: MenuProps) => {
    const renderLink = ({ slug, title }: RelatedPage) => (
        <Link className="menu-link" title={title} to={slug} key={generate()}>
            <span className="menu-link-text">{title}</span>
            <i className="menu-link-icon || fas fa-chevron-right" />
        </Link>
    );
    const getParentsAsArray = (page: RelatedPage) => {
        let currentParent = page;
        const breadcrumbs = [];
        while (currentParent && "parent" in currentParent) {
            breadcrumbs.push(currentParent);
            currentParent = currentParent.parent;
        }

        return breadcrumbs.reverse();
    };

    const breadcrumbs = getParentsAsArray(parent);

    if (!show || (!parent && links.length === 0)) {
        return null;
    }

    return (
        <div className="menu" data-testid="menu">
            {links.length > 0 && (
                <div className="menu-links">
                    <div className="constrain-width">
                        {/* <h6 className="heading-6">Related pages:</h6> */}
                        <div className="menu-list">{links.map(renderLink)}</div>
                    </div>
                </div>
            )}
            {breadcrumbs.length > 0 && (
                <div className="menu-breadcrumb || constrain-width">
                    {breadcrumbs.map((breadcrumb) => (
                        <span key={generate()}>
                            <Link
                                className="menu-breadcrumb-item linked"
                                to={breadcrumb.slug}>
                                {breadcrumb.title}
                            </Link>
                            <i className="menu-breadcrumb-divider || fas fa-chevron-right" />
                        </span>
                    ))}
                    <span className="menu-breadcrumb-item">
                        {currentPage.title}
                    </span>
                </div>
            )}
        </div>
    );
};
