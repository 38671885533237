export const FLAGS = {
    ecommerce: "tah-website-ecommerce",
} as const;

export type FlagKey = keyof typeof FLAGS;
export type Flags = Partial<{
    [key in typeof FLAGS[keyof typeof FLAGS]]: boolean;
}>;

export type FlagApplicationContext = {
    [key: string]:
        | null
        | undefined
        | string
        | number
        | boolean
        | symbol
        | bigint;
};
