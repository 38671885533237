import * as React from "react";

export interface BlockQuoteProps {
    text: string;
    citation?: string;
}

export const BlockQuote: React.FunctionComponent<BlockQuoteProps> = ({
    text,
    citation,
}: BlockQuoteProps) => (
    <>
        <blockquote>
            <p>{text}</p>
        </blockquote>
        <cite>{citation}</cite>
    </>
);
