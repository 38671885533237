import * as React from "react";
import { Icon } from "./icon";

export interface LoaderProps {
    size?: keyof typeof LoaderDimensions | number;
}

export enum LoaderDimensions {
    small = 20,
    normal = 40,
    large = 80,
}

export const Loader = ({ size = LoaderDimensions.normal }: LoaderProps) => {
    const getDimension = () =>
        typeof size === "number" ? size : LoaderDimensions[size];

    return (
        <Icon
            data-testid="loader"
            className="loader"
            style={{
                width: getDimension(),
                height: getDimension(),
            }}
            name="loading"
        />
    );
};
