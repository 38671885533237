import { format } from "date-fns";
import * as React from "react";
import DayPicker from "react-day-picker";
import { classNames } from "@plinknz/tah-website-elements";
import { getMonthsForYear } from "../../utility/months-in-year";

const LOWEST_YEAR = 999;
const HIGHEST_YEAR = 2999;

const yearIsValid = (value: number) =>
    !Number.isNaN(Number(value)) && value > LOWEST_YEAR && value < HIGHEST_YEAR;

interface DatePickerState {
    year: number;
    month: number;
    day: Date;
    touched: string[];
}

const INITIAL_STATE: DatePickerState = {
    year: null,
    month: null,
    day: null,
    touched: [],
};

type ACTIONS =
    | { type: "set_year"; payload: number }
    | { type: "set_month"; payload: number }
    | { type: "set_day"; payload: Date }
    | { type: "add_touched"; payload: string };

function reducer(state: DatePickerState, action: ACTIONS) {
    switch (action.type) {
        case "set_year":
            return { ...state, year: action.payload };
        case "set_month":
            return { ...state, month: action.payload };
        case "set_day":
            return { ...state, day: action.payload };
        case "add_touched": {
            const touched = state.touched.slice();

            if (!touched.includes(action.payload)) {
                touched.push(action.payload);
            }

            return { ...state, touched };
        }
        // istanbul ignore next
        default:
            throw new Error();
    }
}

interface DatePickerProps {
    onChange: (value: string) => void;
    defaultValues?: Partial<DatePickerState>;
    children: React.ReactNode;
}

const DatePickerContext = React.createContext<{
    state: DatePickerState;
    dispatch: React.Dispatch<ACTIONS>;
    onChange: (date: string) => void;
}>({
    state: INITIAL_STATE,
    dispatch: () => undefined,
    onChange: () => {},
});

const DatePickerYear = () => {
    const { state, dispatch } = React.useContext(DatePickerContext);

    return (
        <input
            name="year"
            className={classNames("input", {
                error:
                    !yearIsValid(state.year) && state.touched.includes("year"),
            })}
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = Number(event.currentTarget.value);

                if (!Number.isNaN(Number(value))) {
                    dispatch({ type: "set_year", payload: value });
                }
            }}
            value={state.year || ""}
            placeholder="Enter birth year"
            onBlur={(event) =>
                dispatch({
                    type: "add_touched",
                    payload: event.currentTarget.name,
                })
            }
            data-testid="datepicker-year"
        />
    );
};

const DatePickerMonth = () => {
    const {
        state: { year, month },
        dispatch,
    } = React.useContext(DatePickerContext);
    const months = getMonthsForYear(year);

    return (
        <select
            name="month"
            className="dropdown"
            disabled={!yearIsValid(year)}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                dispatch({
                    type: "set_month",
                    payload: Number(event.currentTarget.value),
                })
            }
            onBlur={(
                event: React.FocusEvent<HTMLInputElement | HTMLSelectElement>
            ) =>
                dispatch({
                    type: "add_touched",
                    payload: event.currentTarget.name,
                })
            }
            value={month !== null ? month : ""}
            data-testid="datepicker-month">
            <option value="" disabled>
                Select month
            </option>
            {months.map((item) => (
                <option
                    key={item.value.valueOf()}
                    value={item.value.getMonth()}>
                    {format(item.value, "MMMM")}
                </option>
            ))}
        </select>
    );
};

const DatePickerCalendar = () => {
    const {
        state: { year, month, day },
        dispatch,
        onChange,
    } = React.useContext(DatePickerContext);

    if (!year || month === null) {
        return null;
    }

    return (
        <DayPicker
            selectedDays={day}
            captionElement={(): null => null}
            month={new Date(year, month)}
            canChangeMonth={false}
            onDayClick={(selectedDay: Date) => {
                const formatedDate = format(selectedDay, "yyyyMMdd");

                onChange(formatedDate);
                dispatch({ type: "set_day", payload: selectedDay });
            }}
        />
    );
};

const DatePickerResult = () => {
    const {
        state: { day },
    } = React.useContext(DatePickerContext);

    if (!day) {
        return null;
    }

    return (
        <p data-testid="datepicker-result">
            <strong>{format(day, "d MMMM, yyyy")}</strong>
        </p>
    );
};

const DatePickerComonent = ({
    onChange,
    defaultValues = {},
    children,
}: DatePickerProps) => {
    const initialState = {
        ...INITIAL_STATE,
        ...defaultValues,
    };

    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <DatePickerContext.Provider value={{ state, dispatch, onChange }}>
            <div data-testid="datepicker">{children}</div>
        </DatePickerContext.Provider>
    );
};

export const DatePicker = Object.assign(DatePickerComonent, {
    Year: DatePickerYear,
    Month: DatePickerMonth,
    Day: DatePickerCalendar,
    Result: DatePickerResult,
});
