import { ApiRequest } from "@plinknz/tah-website-elements";
import type { Marae } from "./marae";
import { getMarae } from "./marae";
import { REGISTRATION_ENDPOINTS } from ".";
import { registrationApi } from "../../service/register/api";

interface TakiwaResponse {
    takiwa: {
        value: string;
    }[];
}

export interface Takiwa {
    description: string | null;
    id: number;
    marae: (Marae | null)[];
}

export const getTakiwa = async (
    api: ApiRequest = registrationApi
): Promise<Takiwa[]> => {
    const response = await api.get<TakiwaResponse>(
        REGISTRATION_ENDPOINTS.groups
    );
    const groups = response.data;
    const marae = await getMarae(api);

    if (!groups) return [];

    const getTakiwaFromList = (name: string) =>
        groups.takiwa.find(({ value }: any) => value === name)?.value;

    return [
        {
            description: getTakiwaFromList("Hautapu") ?? null,
            id: 633035,
            marae: [
                marae.Raketapauma,
                marae.Kuratahi,
                marae["Te Ao Hou"],
                null,
            ],
        },
        {
            description: getTakiwaFromList("Whangaehu") ?? null,
            id: 633034,
            marae: [marae.Tirorangi, marae.Tirohia, marae.Mokai, null],
        },
        {
            description: getTakiwaFromList("Mangawhero") ?? null,
            id: 633037,
            marae: [
                marae.Maungarongo,
                marae.Mangamingi,
                marae["Tuhi Ariki"],
                null,
            ],
        },
        {
            description: getTakiwaFromList("Te Waimarino") ?? null,
            id: 633036,
            marae: [
                marae.Raetihi,
                marae.Marangai,
                marae.Motekatoa,
                marae.Waitahuparae,
            ],
        },
    ];
};
