import * as React from "react";
import { Link } from "react-router-dom";
import { canUseDom } from "@plinknz/tah-website-elements";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface DynamicLinkProps extends React.PropsWithChildren<any> {
    to: string;
}

export const DynamicLink: React.FunctionComponent<DynamicLinkProps> = ({
    to,
    ...props
}: DynamicLinkProps) =>
    canUseDom() &&
    (to.startsWith("/") || to.includes(window.location.hostname)) ? (
        <Link to={to} {...props} />
    ) : (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a href={to} target="_blank" rel="noreferrer" {...props} />
    );
