import type {
    PictureListItemData,
    PictureListTypeData,
} from "@plinknz/tah-website-elements";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useVisible } from "react-visible-image";
import { generate } from "shortid";
import { classNames, formatMultilineText } from "@plinknz/tah-website-elements";

export interface PictureListProps {
    data: PictureListTypeData;
}

const PictureListItem = ({
    title,
    picture,
    subtitle,
    description,
}: PictureListItemData) => {
    const item = React.useRef<HTMLDivElement>(null);
    const isVisible = useVisible(item);
    const profileClass = classNames("picture-list-item");

    return (
        <div className={profileClass} ref={item} key={generate()}>
            <div className="picture-list-item-inner">
                <div
                    className="picture-list-item-image"
                    style={{
                        backgroundImage: isVisible
                            ? `url('${picture?.url}')`
                            : "",
                    }}
                />

                <div className="picture-list-item-content">
                    {title && (
                        <h3 className="picture-list-item-title">{title}</h3>
                    )}
                    {subtitle && (
                        <h5 className="picture-list-item-subtitle">
                            {subtitle}
                        </h5>
                    )}
                    {description && (
                        <div
                            className="picture-list-item-description"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: formatMultilineText(description),
                            }}
                        />
                    )}
                </div>
            </div>
            {description && (
                <ReactMarkdown className="picture-list-item-text">
                    {description}
                </ReactMarkdown>
            )}
        </div>
    );
};

export const PictureList = ({ data }: PictureListProps) => (
    <div className="picture-list || constrain-width" data-testid="picture-list">
        <h2 className="heading-2">{data.profileHeading}</h2>
        <div className="picture-list">
            {data?.profiles.map(PictureListItem)}
        </div>
    </div>
);
