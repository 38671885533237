import { ApiRequest } from "@plinknz/tah-website-elements";
import { registrationApi } from "../../service/register/api";
import { REGISTRATION_ENDPOINTS } from ".";

type HapuSet = {
    [K in keyof typeof HapuList]: Hapu;
};

enum HapuList {
    "Rangituhia" = "Rangituhia",
    "Parenga" = "Parenga",
    "Uenukumanawawiri" = "Uenukumanawawiri",
    "Tuhi Ariki" = "Tuhi Ariki",
    "Tui-o-Nuku" = "Tui-o-Nuku",
    "Rangihaereroa" = "Rangihaereroa",
    "Rangiteauria" = "Rangiteauria",
    "Tongaiti" = "Tongaiti",
    "Patutokotoko" = "Patutokotoko",
    "Hioi" = "Hioi",
    "Uenuku" = "Uenuku",
    "Tamakana" = "Tamakana",
    "Tupoho" = "Tupoho",
    "Rangi-ki-tai" = "Rangi-ki-tai",
}

interface HapuResponse {
    hapu: { value: string }[];
}

export interface Hapu {
    description: string;
    id: number;
}

export const getHapu = async (
    api: ApiRequest = registrationApi
): Promise<HapuSet> => {
    try {
        const { data } = await api.get<HapuResponse>(
            REGISTRATION_ENDPOINTS.groups
        );
        const hapu = data?.hapu;
        const getHapuFromList = (name: string) => {
            const h = hapu?.find(({ value }) => value === name);

            if (!h) {
                console.error(name, hapu);
                throw new Error(`Could not find get hapu ${name}`);
            }

            return h.value;
        };

        return {
            [HapuList.Rangituhia]: {
                description: getHapuFromList("Ngāti Rangituhia"),
                id: 633015,
            },
            [HapuList.Parenga]: {
                description: getHapuFromList("Ngāti Parenga"),
                id: 633006,
            },
            [HapuList.Uenukumanawawiri]: {
                description: getHapuFromList("Ngāti Uenukumanawawiri"),
                id: 633007,
            },
            [HapuList["Tuhi Ariki"]]: {
                description: getHapuFromList("Ngāti Tuhi Ariki"),
                id: 633008,
            },
            [HapuList["Tui-o-Nuku"]]: {
                description: getHapuFromList("Ngāti Tui-o-Nuku"),
                id: 633009,
            },
            [HapuList.Rangihaereroa]: {
                description: getHapuFromList("Ngāti Rangihaereroa"),
                id: 633010,
            },
            [HapuList.Rangiteauria]: {
                description: getHapuFromList("Ngāti Rangiteauria"),
                id: 633011,
            },
            [HapuList.Tongaiti]: {
                description: getHapuFromList("Ngāti Tongaiti"),
                id: 633012,
            },
            [HapuList.Patutokotoko]: {
                description: getHapuFromList("Ngāti Patutokotoko"),
                id: 633013,
            },
            [HapuList.Hioi]: {
                description: getHapuFromList("Ngāti Hioi"),
                id: 633014,
            },
            [HapuList.Uenuku]: {
                description: getHapuFromList("Ngāti Uenuku"),
                id: 633016,
            },
            [HapuList.Tamakana]: {
                description: getHapuFromList("Ngāti Tamakana"),
                id: 633017,
            },
            [HapuList.Tupoho]: {
                description: getHapuFromList("Ngāti Tupoho"),
                id: 633018,
            },
            [HapuList["Rangi-ki-tai"]]: {
                description: getHapuFromList("Ngāti Rangi-ki-tai"),
                id: 633019,
            },
        };
    } catch (error: unknown) {
        console.warn(error);
        throw error;
    }
};
