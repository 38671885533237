import { lazy } from "@loadable/component";
import * as React from "react";
import { Loader } from "./loader";
import { ServerSuspense } from "./server-suspense";

interface AsyncBlockProps<T = unknown> {
    name: string;
    namedExport: string;
    data: T;
}

export function AsyncBlock<P>({ name, namedExport, data }: AsyncBlockProps<P>) {
    const Component = lazy<{ data: P }>(async () =>
        import(`../components/content-blocks/${name}`).then((module) => ({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            default: module[namedExport],
        }))
    );

    return (
        <ServerSuspense
            fallback={
                <div className="splash">
                    <Loader />
                </div>
            }>
            <Component data={data} />
        </ServerSuspense>
    );
}
