import { classNames } from "@plinknz/tah-website-elements";
import * as React from "react";
import { Hapu } from "../../config/register/hapu";
import { Marae } from "../../config/register/marae";
import { Takiwa } from "../../config/register/takiwa";

interface MaraePickerProps {
    takiwa: Takiwa[];
    name: string;
    numberOfColumns?: number;
    allowNonSelection?: boolean;
    onClick: ({
        takiwa,
        marae,
    }: {
        takiwa: number;
        marae: number;
        hapu: Hapu[];
    }) => void;
}

export const MaraePicker = ({
    takiwa,
    numberOfColumns = 4,
    allowNonSelection = false,
    name,
    onClick,
}: MaraePickerProps) => {
    const columns = new Array<0>(numberOfColumns).fill(0);

    const renderHapu = (hapu: Hapu) => (
        <p className="selection-panels-text" key={hapu.id}>
            {hapu.description}
        </p>
    );
    const renderEmptyCol = (key: number) => (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label key={key} className="selection-panels-col" />
    );
    const renderOption = (x: number, y: number) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const maraeItem = takiwa[y].marae[x] as Marae;

        if (!maraeItem) {
            return null;
        }

        const labelClass = classNames("selection-panels-col", {
            "is-selectable": !maraeItem.disabled,
            "is-disabled": maraeItem.disabled,
        });

        return (
            <React.Fragment key={maraeItem.id}>
                <input
                    type="radio"
                    name={name}
                    id={maraeItem.id.toString()}
                    className="selection-panels-radio"
                    disabled={maraeItem.disabled}
                    onClick={() =>
                        onClick({
                            takiwa: takiwa[y].id,
                            marae: maraeItem.id,
                            hapu: maraeItem.hapu,
                        })
                    }
                />
                <label htmlFor={maraeItem.id.toString()} className={labelClass}>
                    <div className="selection-panels-col-inner">
                        <h5>{maraeItem.description}</h5>
                        <div>{maraeItem.hapu.map(renderHapu)}</div>
                    </div>
                </label>
            </React.Fragment>
        );
    };
    const renderRow = (_empty: never, x: number) => (
        <div key={x} className="selection-panels-row">
            {columns.map((_, y) =>
                takiwa[y].marae[x] === null
                    ? renderEmptyCol(x * y)
                    : renderOption(x, y)
            )}
        </div>
    );
    const renderTakiwa = (takiwaItem: Takiwa) => (
        <div className="selection-panels-col" key={takiwaItem.id}>
            <h4>{takiwaItem.description}</h4>
        </div>
    );

    return (
        <div data-testid="marae-picker" className="selection-panels">
            <div className="selection-panels-row">
                {takiwa.map(renderTakiwa)}
            </div>
            {columns.map(renderRow)}
            {allowNonSelection && (
                <div className="selection-panels-row">
                    <div className="selection-panels-col" />
                    <div className="selection-panels-col" />
                    <div className="selection-panels-col" />
                    <input
                        type="radio"
                        id="unknown"
                        className="selection-panels-radio"
                        name="unknown"
                        onChange={() =>
                            onClick({
                                takiwa: null,
                                marae: null,
                                hapu: [],
                            })
                        }
                    />
                    <label
                        htmlFor="unknown"
                        className="selection-panels-col is-selectable">
                        <div className="selection-panels-col-inner">
                            I don&apos;t know
                        </div>
                    </label>
                </div>
            )}
        </div>
    );
};
